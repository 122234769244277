.transaction-history {
  padding: 2rem;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.transaction-history ul {
  list-style-type: none;
  padding: 0;
}

.transaction-history li {
  background-color: #f0f0f0;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 4px;
}